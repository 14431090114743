import { useCallback, useEffect, useRef, useState } from 'react';
export function useIndicatorContentTransition({
  value,
  duration,
  shouldSkipTransition,
  measureElement
}) {
  const [displayedValue, setDisplayedValue] = useState(value);
  const [opacity, setOpacity] = useState(1);
  const [targetWidth, setTargetWidth] = useState(undefined);
  const animationStateRef = useRef({
    currentTransitionId: 0,
    isTransitioning: false,
    pendingValue: null
  });
  const cancelTransitionRef = useRef(() => {});
  const startTransition = useCallback((newValue, oldValue) => {
    const state = animationStateRef.current;
    cancelTransitionRef.current();
    if (shouldSkipTransition(oldValue, newValue)) {
      setOpacity(1);
      setDisplayedValue(newValue);
      setTargetWidth(measureElement());
      state.isTransitioning = false;
      state.pendingValue = null;
      return;
    }
    state.isTransitioning = true;
    const transitionId = ++state.currentTransitionId;
    setOpacity(0);
    let transitionCompleteTimeout;
    let rafId1;
    let rafId2;
    const fadeOutTimeout = setTimeout(() => {
      if (transitionId !== state.currentTransitionId) {
        return;
      }
      setDisplayedValue(newValue);
      rafId1 = requestAnimationFrame(() => {
        if (transitionId !== state.currentTransitionId) {
          return;
        }
        const newWidth = measureElement();
        setTargetWidth(newWidth);
        rafId2 = requestAnimationFrame(() => {
          if (transitionId !== state.currentTransitionId) {
            return;
          }
          setOpacity(1);
          transitionCompleteTimeout = setTimeout(() => {
            if (transitionId !== state.currentTransitionId) {
              return;
            }
            state.isTransitioning = false;
            if (state.pendingValue) {
              const pendingValue = state.pendingValue;
              state.pendingValue = null;
              startTransition(pendingValue, newValue);
            }
          }, duration);
        });
      });
    }, duration / 2);

    // Clear both timeouts when unmounting or when the dependencies change
    cancelTransitionRef.current = () => {
      clearTimeout(fadeOutTimeout);
      clearTimeout(transitionCompleteTimeout);
      cancelAnimationFrame(rafId1);
      cancelAnimationFrame(rafId2);
    };
  }, [duration, measureElement, shouldSkipTransition]);
  useEffect(() => {
    if (value === displayedValue) {
      return;
    }
    const state = animationStateRef.current;
    if (state.isTransitioning) {
      state.pendingValue = value;
      return;
    }
    startTransition(value, displayedValue);
  }, [value, displayedValue, startTransition]);
  useEffect(() => {
    return () => cancelTransitionRef.current();
  }, []);
  return {
    displayedValue,
    opacity,
    targetWidth
  };
}